@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.admin-container {
  padding: 1% 7%;
  font-family: "Open Sans";
}

.admin-categories {
  display: flex;
  margin-top: 2rem;
}

.admin-underline {
  background-color: var(--primary-yellow);
  width: 50%;
}

.admin-item {
  text-decoration: none;
  color: var(--secondary-black);
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  margin-right: 2rem;
}

.admin-item p {
  margin: 0;
}

.admin-item.active p {
  display: inline-block;
  line-height: 1;
  margin: 0;
  cursor: inherit;
}

.admin-item.active span {
  display: inline-block;
  width: 100%;
  height: 2px;
}

/* .active {
  color: #fdb303;
} */

.admin-dropdown {
  width: 21.5% !important;
  height: 48px !important;
  padding: 0 8px !important;
  border: 1px solid #5c5c5c !important;
  font-size: 14px !important;
  color: #5c5c5c !important;
}

.admin-dropdown:focus {
  border: 1px solid #5c5c5c !important;
  box-shadow: none !important;
}

.job-time {
  color: #bfbfbf;
  font-size: 15px;
  margin-left: 10px;
  z-index: -1;
  /* position: relative; */
}

.job-carousel {
  position: absolute;
}

.job-time span {
  color: #f00000;
  font-size: 15px;
}

.project-underline {
  background-color: var(--primary-yellow);
  display: block;
  height: 1px;
}

.quote-install-options {
  display: flex;
  align-items: center;
}

.project-name-container {
  display: flex;
  align-items: center;
}

.project-header {
  font-size: 22px;
  color: #1b1b1b;
  font-family: "Open Sans";
  font-weight: 500;
}

.project-header p {
  margin-bottom: 0;
}

.project-name {
  font-size: 19px;
  color: #1b1b1b;
  font-family: "Open Sans";
  margin-left: 7px;
}

.quote-appliances {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.createquote-name {
  display: flex;
  align-items: baseline;
  font-size: 20px;
}

.createquote-name p {
  width: 25% !important;
}

.quote-name-input .form-control {
  width: 135% !important;
}

.quote-name-input .form-control-lg {
  border-radius: 4px !important;
}

.quote-inputs-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.quote-inputs-details {
  width: 100%;
  padding: 10px 0 !important;
}

.create-quote-dropdown {
  /* width: 27% !important; */
  font-size: 14px !important;
  color: #1b1b1b;
}

.form-select {
  padding: 10px 12px !important;
  border-radius: 4px !important;
}

.create-quote-dropdown:focus {
  border: 1px solid #1b1b1b !important;
  box-shadow: none !important;
}

.first-header {
  font-size: calc(1.275rem + 0.3vw) !important;
  color: #1b1b1b;
}

.first-column {
  width: 25% !important;
}

.second-column,
.third-column {
  width: 25% !important;
}

.quote-why {
  width: 50% !important;
  margin-right: 0 !important;
}

.quote-why .input-width .form-control {
  height: 90px;
}

.why-container {
  align-items: flex-start !important;
}

.second-column {
  margin-right: 5rem;
}

.quote-title {
  font-size: 16px !important;
}

.quote-title span {
  color: #e8253f;
}

.quote-table-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.input-radius .form-control-lg {
  border-radius: 4px !important;
}

.quote-underline {
  border-bottom: 1px solid #fdb303;
  width: 15%;
  margin: auto;
}

.quote2-header {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.quote3-why {
  width: 25% !important;
}

.quote3-why .input-width .form-control {
  height: 90px;
}

.confirm-first-column {
  width: 15% !important;
}

.confirm-second-column,
.confirm-third-column {
  width: 35% !important;
}

.confirm-quote-img {
  width: 30%;
}

.confirm-quote-underline {
  border-bottom: 1px solid #fdb303;
  /* width: 15%; */
  margin: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}
tr {
  width: 100%;
}

td {
  text-align: left;
}

td.confirm-second-column {
  font-weight: 500;
}

caption {
  caption-side: top;
  color: #1b1b1b !important;
  font-weight: 500;
}
@media screen and (max-width: 995px) {
  caption {
    font-size: 14px !important;
  }
  tr {
    font-size: 12px;
    margin: 10px 0;
  }
  .column-title {
    font-size: 14px;
  }

  .confirm-first-column {
    display: none;
  }

  .quote2-header {
    font-size: 14px !important;
  }
  .project-header {
    font-size: 14px;
  }

  .project-header p {
    margin-bottom: 0;
  }

  .project-name {
    font-size: 14px;
  }

  .quote-appliances {
    flex-wrap: wrap;
  }

  .quote-appliances .equipment {
    height: 125px;
    width: 110px !important;
  }

  .quote-info {
    font-size: 13px;
  }

  .createquote-name p {
    width: 28% !important;
    font-size: 13px !important;
  }

  .quote-name-input .form-control {
    width: 100% !important;
  }

  .quote-title {
    font-size: 12px !important;
  }

  .second-column,
  .third-column {
    width: 40% !important;
    font-size: 13px !important;
  }

  .first-header {
    font-size: 11px !important;
    width: 31% !important;
  }

  .input-width .calc-input {
    font-size: 13px !important;
  }

  .second-column {
    margin-right: 0.5rem;
  }

  .create-quote-dropdown {
    font-size: 13px !important;
  }

  .form-select {
    padding: 9px 5px !important;
  }

  .second-column .form-control,
  .third-column .form-control {
    padding: 0.3rem 0.5rem !important;
  }

  .first-column {
    width: 32% !important;
  }

  .pay-options-text p {
    font-size: 10px;
    margin: 0;
  }

  .quote-why .input-width .form-control {
    height: 60px;
    width: 160%;
  }

  .why-container .first-column {
    width: 28% !important;
  }

  .decs-width .project-header p {
    width: 36%;
    margin-top: 4px !important;
  }

  .decs-width {
    align-items: flex-start;
  }
}

@media screen and (max-width: 590px) {
  .admin-container {
    padding: 1% 5px;
  }

  .admin-item {
    font-size: 12px;
  }

  .admin-dropdown {
    width: 45.5% !important;
    height: 40px !important;
    font-size: 12px !important;
  }

  .job-time {
    font-size: 12px;
  }

  .job-time span {
    font-size: 12px;
  }

  .project-header {
    font-size: 12px;
  }

  .project-header p {
    margin-bottom: 0;
  }

  .project-name {
    font-size: 12px;
  }

  .quote-appliances {
    flex-wrap: wrap;
  }

  .quote-appliances .equipment {
    height: 125px;
    width: 110px !important;
  }

  .quote-info {
    font-size: 12px;
  }

  .createquote-name p {
    width: 22% !important;
    font-size: 12px !important;
  }

  .quote-name-input .form-control {
    width: 100% !important;
    margin-left: 2rem;
  }

  .quote-title {
    font-size: 10px !important;
  }

  .second-column,
  .third-column {
    width: 40% !important;
    font-size: 12px !important;
  }

  .first-header {
    font-size: 11px !important;
    width: 31% !important;
  }

  .second-column {
    margin-right: 0.5rem;
  }

  .create-quote-dropdown {
    font-size: 11px !important;
  }

  .form-select {
    padding: 9px 5px !important;
  }

  .second-column .form-control,
  .third-column .form-control {
    padding: 0.3rem 0.5rem !important;
  }

  .quote-name-input .form-control {
    margin-left: 1rem;
  }

  .first-column {
    width: 32% !important;
  }

  .pay-options-text p {
    font-size: 10px;
    margin: 0;
  }

  .quote-why .input-width .form-control {
    height: 60px;
    width: 160%;
  }

  .why-container .first-column {
    width: 28% !important;
  }

  .decs-width .project-header p {
    width: 36%;
    margin-top: 4px !important;
  }

  .decs-width {
    align-items: flex-start;
  }
}
