@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.signup-container {
  padding: 30px 40px;
  height: 100%;
  color: var(--secondary-black);
  position: relative;
  font-family: "Open Sans", sans-serif;
}

.form-container {
  padding: 50px 109px;
  height: 100%;
  position: relative;
}

.login-form-container {
  padding: 20px 70px;
  height: 100%;
  position: relative;
}
.form-header {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 64px;
}

.form-sub {
  display: inline-block;
  font-weight: 400;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  margin-left: 35px;
  cursor: pointer;
}

.header-dot {
  color: var(--primary-yellow);
}

.onboard-form {
  margin-top: 60px;
}

.login-onboard-form {
  margin-top: 30px;
}

.input-label {
  font-size: 16px;
  margin-bottom: 8px;
}

.group-input {
  margin-bottom: 30px;
}

.group-input input {
  height: 48px;
}

.onboard-form-button {
  width: 100%;
}

.terms-text {
  font-size: 12px;
  font-weight: 400;
}

.term-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.base-question {
  margin-top: 5%;
}

.base-question p {
  text-align: center;
}

.base-question p span {
  color: rgba(253, 179, 3, 1);
  font-weight: 600;
  cursor: pointer;
}

#message {
  margin-top: 10px;
}

#message p {
  font-size: 12px;
  white-space: pre-line;
  margin-bottom: 0;
  color: #5c5c5c;
}

.forgot-text {
  text-align: right;
  color: #1b1b1b;
  font-size: 12px;
  margin-top: 5px;
  text-decoration: none;
}

.forgot-text:hover {
  color: #1b1b1b;
}

.rline,
.lline {
  border-top: 1px solid #f2f2f2;
  width: 38%;
}
.rline {
  margin-right: 5px;
}

.lline {
  margin-left: 5px;
}

.text {
  font-size: 12px;
  color: #1b1b1b;
  font-weight: 400;
}

.left {
  margin-left: 10%;
}

.social-container {
  display: flex;
  justify-content: center;
}

.social-img {
  width: 6%;
  margin-right: 12%;
  cursor: pointer;
}

.valid {
  color: green;
  display: block;
  font-size: 14px;
}

.invalid {
  color: red;
  display: block;
  font-size: 14px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 0 6rem;
}

.logo-sidebar img {
  width: 75px;
  height: 65px;
}

.menu {
  cursor: pointer;
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-text {
  color: #1b1b1b;
  font-weight: 400;
  font-size: 42px;
}

.hourglass {
  width: 15%;
}

.empty-button {
  text-decoration: none;
  background-color: #1b1b1b;
  width: 35%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
}

.empty-button:hover {
  color: #ffffff;
}

.job-container {
  margin: auto;
  padding: 0 25%;
}

.job-text {
  color: #1b1b1b;
  font-weight: 400;
  font-size: 24px;
  width: 25%;
  margin-bottom: 1.5rem !important;
}

.dropdown-label {
  font-size: 24px;
}

.dropdown:focus {
  border: 1px solid #1b1b1b !important;
  box-shadow: none !important;
}

.float {
  float: left;
}

.input-width {
  width: 100%;
}

.profile-container {
  padding: 3% 7%;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-image {
  display: flex !important;
  /* justify-content: space-between; */
  align-items: center;
  width: 50%;
}

.image {
  width: 168px;
  height: 174px;
  margin-right: 2.5rem;
}

.status {
  display: flex !important;
  /* justify-content: space-between; */
  align-items: center;
}

.emain-text {
  font-size: 14px;
  /* margin-bottom: 1.5rem; */
  display: block;
}

.edit-button {
  text-decoration: none;
  background-color: #1b1b1b;
  width: 90%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 2.6rem !important;
}

.edits-button {
  text-decoration: none;
  background-color: #1b1b1b;
  width: 200px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 2.6rem !important;
}

.edits-button:hover {
  color: #ffffff;
}

.edit-button:hover {
  color: #ffffff;
}

.profile-green {
  font-weight: 400;
  color: #65dd6a;
}

.profile-red {
  font-weight: 400;
  color: rgb(214, 22, 22);
}

.profile-text {
  font-weight: 400;
}

.notification-dropdown {
  width: 20% !important;
}

.notification-dropdown:focus {
  border: 1px solid #1b1b1b !important;
  box-shadow: none !important;
}

.date-text {
  color: #bfbfbf;
  font-size: 14px;
  margin-left: 0.5rem;
}

.quotes-container {
  display: flex;
}

.quotes {
  height: 260px;
  padding: 2% 2%;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 0.5rem !important;
}

.px-quotes {
  width: 464px;
}

.pc-quote {
  width: 46%;
}

.skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 100%;
  /* margin-bottom: 1.5rem; */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 203px;
  height: 260px;
  border: 1px dashed #000000;
  border-style: dashed !important;
  background-color: #f2f2f2;
  cursor: pointer;
}

.quote-status {
  color: #65dd6a;
  font-size: 14px;
  float: right;
}

.project-container {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.project-text {
  color: #bfbfbf;
  font-size: 14px;
}

.quote-name {
  font-weight: 400;
  font-size: 30px;
}

.quote-text {
  font-size: 12px;
  /* margin-right: 2rem; */
}

.arrow {
  margin-right: 1rem;
}

.quotes-wrap {
  display: flex;
  flex-wrap: wrap;
}

.Quote-Payment-Container {
  padding: 3rem;
}

.Quote-details {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1rem !important;
  margin-top: 3rem !important;
}

.Quote-Progress {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 25px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem !important;
}

.Quote-Text {
  font-size: 14px;
}

.underleft {
  text-decoration: underline;
  text-decoration-color: #fdb303;
  text-decoration-thickness: 2px;
}

.change-password-info {
  color: #fdb303;
  font-size: 14px;
}

.details-container {
  margin-left: 1rem !important;
}

.congratText {
  font-size: 16px;
  margin-top: 1rem;
}

.quote-dropdown {
  width: 30% !important;
  font-size: 14px !important;
  color: #1b1b1b !important;
  height: 42px !important;
}

.quote-dropdown:focus {
  border: 1px solid #1b1b1b !important;
  box-shadow: none !important;
}

.Bank-Details {
  border: 1px solid #bfbfbf;
  width: 40%;
  padding: 25px;
  border-radius: 4px;
  margin-left: 1.25rem !important;
}

.bank-name {
  font-weight: 400;
  margin-bottom: 5px;
}

.quote {
  width: 482px;
  height: 260px;
  padding: 1% 2%;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.quote-info {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.acc-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
}

.quote-submit {
  width: 30% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
}

.quote-delete-button {
  height: 48px;
  width: 100%;
  border-radius: 4px !important;
  font-size: 16px;
  color: white !important;
  /* background-color: var(--secondary-black) !important; */
}

.radio-btns {
  display: flex;
  align-items: center;
}

.radio-btns input {
  margin-right: 5px;
}

.radio-btns label {
  margin-right: 15px;
  font-size: 12px;
}

.radio-label {
  font-size: 15px;
  margin-bottom: 5px;
}

.radio-container {
  margin-bottom: 14px;
}

.installer-quote {
  width: 30%;
  padding: 2% 2%;
  border-radius: 4px;
  cursor: pointer;
}

.star-container {
  flex-direction: "row";
  justify-content: "space-between";
}

.svg {
  width: 13px;
  margin-right: 2px;
}

.svg-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.svg-flex p {
  font-size: 15px;
}

.view-quote {
  width: 60% !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
}

.installer-conatainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem !important;
  margin-bottom: 25px !important;
}

.pointer {
  cursor: pointer;
}

.install-container {
  display: flex;
  /* align-items: center; */
  width: 100%;
}

.install-text {
  width: 20%;
  font-size: 24px;
}

.install-input {
  width: 30% !important;
}

.job-dropdown {
  width: 37.5% !important;
  height: 40px !important;
}

.job-dropdown:focus {
  border: 1px solid #1b1b1b !important;
  box-shadow: none !important;
}

.job-dropdown-container {
  width: 100% !important;
}

.uploadButton {
  display: none;
}

.uploadLablel {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fdb303;
  color: #1b1b1b;
  text-align: center;
  width: 37.5%;
}

.upload-submit {
  width: 20% !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
}

.job-complete-container {
  margin-top: 1rem !important;
}

.job-appliance-container {
  padding: 2rem 3rem 0;
}

.appliance-container {
  display: flex;
  justify-content: space-between;
}

.appliances {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  height: 445px;
  overflow-y: scroll;
}

.appliances::-webkit-scrollbar {
  display: none;
}

.appliances {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.appliance-quantity {
  width: 60%;
  height: 445px;
}

.left-header {
  display: flex;
  align-items: center;
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff7e6;
  padding: 10px 25px;
  height: 48px;
}

.headers p {
  font-size: 16px;
  font-weight: 700;
  color: #1b1b1b;
}

.appliance-content {
  background-color: #f2f2f2;
  height: 395px;
}

.left-numApp {
  display: flex;
  align-items: center;
  width: 70%;
}

.item-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 48px;
  border-bottom: 1px solid #ffffff;
}

.numAppliance {
  display: flex;
  align-items: center;
  margin-right: 1rem !important;
}

.qty-text {
  font-size: 14px;
}

.qty-add-bg {
  background-color: #c4c4c4;
  padding: 5px;
  border-radius: 50%;
  margin-right: 0.3rem;
}

.qty-minus-bg {
  background-color: #c4c4c4;
  padding: 9px 5px;
  border-radius: 50%;
  margin-left: 0.3rem;
}

.qty {
  background-color: #ffffff;
  border: 1px solid #1b1b1b;
  padding: 1px 18px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.increment {
  width: 10.67px;
  height: 10.67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.decrement {
  width: 9.33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount {
  margin-right: 3rem;
}

.type-text {
  font-size: 14px;
  color: #1b1b1b;
}

.watt-qty {
  background-color: #ffffff;
  border: 1px solid #1b1b1b;
  padding: 1px 25px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appliance-btn {
  float: right;
  width: 15% !important;
  margin-top: 1.5rem;
}

.edit-profile-container {
  padding: 3rem 5rem;
}

.edit-profile {
  margin: 0.5rem 1rem;
}

.edit-profile-header {
  font-size: 32px;
}

.edit-profile-input {
  display: flex;
}

.edit-password-btn {
  color: #1b1b1b;
  font-size: 16px;
  border: 1px solid #1b1b1b;
  padding: 10px 30px;
  border-radius: 4px;
}

.profile-red {
  font-weight: 400;
  color: rgb(214, 22, 22);
}

.custom__image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.custom__image-container img {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  cursor: pointer;
  width: 200px;
  height: 200px;
  /* border: solid 1px black; */
  /* border-radius: 5px; */
  object-fit: contain;
}

.edit-error {
  font-size: 13px;
}

.profile-img {
  text-align: center;
  margin-right: 1.5rem;
}

.profile-image {
  display: block;
  margin-bottom: 2rem;
}

.edit-btn-text {
  font-size: 16px;
  font-weight: 700;
}

.edit-password-btn:hover {
  color: #1b1b1b;
}

.profile-inputs {
  width: 50%;
}

@media screen and (max-width: 995px) {
  .custom__image-container img {
    width: 100%;
    height: 95px;
    margin-right: 15px;
  }

  .custom__image-container {
    height: 90px;
  }
  .appliances {
    width: 100%;
    height: 320px;
  }

  .appliance-quantity {
    width: 100%;
  }

  .appliance-container {
    display: block;
  }

  .appliance-btn {
    float: none;
    width: 70% !important;
    margin-top: 1.5rem;
  }

  .app-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .job-dropdown {
    width: 76.5% !important;
  }

  .uploadLablel {
    width: 76.5%;
  }

  .edit-profile-container {
    padding: 3rem 5rem;
  }

  .edit-profile {
    margin: 0.5rem 1rem;
  }

  .edit-profile-header {
    font-size: 32px;
  }

  .edit-profile-input {
    display: block;
  }

  .edit-password-btn {
    color: #1b1b1b;
    font-size: 16px;
    border: 1px solid #1b1b1b;
    padding: 10px 30px;
    border-radius: 4px;
  }

  .profile-img {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-image {
    display: block;
    margin-bottom: 2rem;
    width: 60%;
    height: 150px;
  }

  .edit-btn-text {
    font-size: 16px;
    font-weight: 700;
  }

  .edit-password-btn {
    padding: 5px 17px;
  }

  .edit-password-btn:hover {
    color: #1b1b1b;
  }

  .profile-inputs {
    width: 50%;
  }

  .custom__image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .custom__image-container img {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    cursor: pointer;
    width: 200px;
    height: 200px;
    /* border: solid 1px black; */
    border-radius: 5px;
    object-fit: cover;
  }

  .edit-error {
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .custom__image-container img {
    width: 100%;
    height: 95px;
    margin-right: 15px;
  }

  .custom__image-container {
    height: 90px;
  }
  /* .profile-image {
    width: 80% !important;
  } */

  .profile-img {
    text-align: left;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .edit-password-btn {
    padding: 5px 17px;
  }

  .edit-btn-text {
    font-size: 12px;
    font-weight: 700;
  }

  .edit-profile-input {
    display: block;
  }

  .profile-img {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-inputs {
    width: 100%;
  }
  .form-container {
    padding: 0px;
  }

  .login-form-container {
    padding: 0px;
  }

  .input-label {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .emain-text {
    font-size: 10px;
    margin-bottom: 10px;
    /* display: inline; */
  }

  .image {
    /* width: 68px; */
    /* height: 64px; */
    margin-right: 5px;
  }

  .edit-button {
    width: 100%;
    height: 30px;
    font-size: 12px;
    margin-top: 3rem !important;
  }

  .Quote-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
  }

  .edits-button {
    /* width: 80%; */
    height: 40px;
  }

  .Bank-Details {
    width: 50%;
    padding: 25px;
    margin-left: 0 !important;
  }

  .dropdown-label {
    font-size: 14px;
  }

  .quote-dropdown {
    font-size: 14px !important;
  }

  .quote-delete-button {
    height: 40px;
    /* width: 60%; */
  }

  .Quote-Progress {
    margin: 0 !important;
  }

  .Quote-details {
    padding-left: 0 !important;
  }

  .quote {
    margin-right: 5px !important;
  }

  .details-container {
    margin-left: 0 !important;
  }

  .quote-dropdown {
    width: 60% !important;
  }

  .back-btn {
    display: none;
  }

  .hourglass {
    width: 50%;
  }

  .empty-button {
    width: 90%;
    height: 48px;
    font-size: 16px;
  }

  .job-container {
    padding: 0 10%;
  }

  .Quote-Progress {
    display: none;
  }

  .installer-conatainer {
    display: block;
  }

  .installer-quote {
    width: 100%;
    padding: 5%;
    margin: 0 0 10px;
  }

  .radio-container {
    margin: 1rem;
  }

  .quotes-container {
    display: block;
  }

  .quotes {
    width: 48%;
    height: 260px;
    padding: 2% 2%;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 5px !important;
  }

  .install-text {
    width: 35%;
    font-size: 24px !important;
  }

  .job-text {
    font-size: 24px;
    width: 55%;
  }

  .install-input {
    width: 50% !important;
  }
}

@media screen and (max-width: 590px) {
  .install-container {
    align-items: center;
  }
  .edit-profile-container {
    padding: 1rem;
  }

  .edit-profile {
    margin: 0.5rem;
  }

  /* .edit-profile-input {
    display: block;
  } */

  .edit-password-btn {
    margin-left: 0rem;
  }

  .edit-btn-text {
    font-size: 12px;
  }

  /* .profile-img {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .profile-image {
    width: 100px !important;
    height: 90px;
    margin-bottom: 0;
  }

  .profile-inputs {
    width: 100%;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    padding: 0 1rem;
  }

  .signup-container {
    padding: 0px 0;
    width: 100%;
  }

  .profile-container {
    padding: 1% 5px;
  }

  .image {
    width: 75px;
    height: 85px;
    object-fit: contain;
    margin-right: 5px;
  }

  .emain-text {
    font-size: 10px;
    margin-bottom: 2px;
    width: 120%;
    /* display: inline; */
  }

  .icons {
    margin-right: 2px;
  }

  .title {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .dashboard-header {
    align-items: flex-start;
  }

  .status {
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
    margin-left: 25px;
  }

  .quote-icon {
    width: 5%;
  }

  .bulb {
    width: 30%;
  }

  .edit-button {
    width: 100%;
    height: 30px;
    font-size: 12px;
    margin-top: 10px !important;
  }

  .dashboard-image {
    align-items: flex-start;
    width: 60%;
  }

  .notification-dropdown {
    width: 60% !important;
    font-size: 12px !important;
  }

  .quotes-container {
    display: block;
  }

  .quotes {
    width: 100%;
    height: 180px;
    padding: 2% 2%;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }

  .add {
    width: 100%;
    height: 150px;
  }

  .Quote-Payment-Container {
    padding: 1rem;
  }

  .Quote-details {
    display: block;
    width: 100%;
    padding-left: 0 !important;
    margin-top: 1rem !important;
  }

  .edits-button {
    width: 60%;
    height: 40px;
  }

  .quote-delete-button {
    height: 40px;
    width: 60%;
  }

  .single-quote {
    width: 50% !important;
  }

  .Bank-Details {
    width: 100%;
    padding: 20px;
    margin-left: 0 !important;
  }

  .bank-name {
    font-size: 20px;
  }

  .acc-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
  }

  .quote {
    width: 100%;
    height: 210px;
  }

  .quote-info {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .quote-submit {
    width: 60% !important;
  }

  .quote-status {
    font-size: 10px;
    /* float: none; */
    margin-bottom: 5px;
  }

  .calender {
    width: 10%;
  }

  .project-text {
    font-size: 10px;
    margin-bottom: 0;
  }

  .quote-name {
    font-size: 20px;
  }

  .quote-text {
    font-size: 10px;
    margin-right: 5px;
  }

  .arrow {
    margin-right: 5px;
    width: 20%;
  }

  .congratText {
    text-align: center;
  }

  .quote-dropdown-container {
    width: 100% !important;
  }

  .quote-menu {
    margin: 1rem;
  }

  .quote-dropdown {
    width: 70% !important;
  }

  .date-text {
    font-size: 10px;
    margin-left: 0.1rem;
  }

  .project-container {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  /* .back-btn {
        display: none;
    } */

  .group-input {
    margin-bottom: 15px;
  }

  .group-input input {
    height: 40px;
  }

  .input-label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .form-container {
    padding: 10px;
  }

  .login-form-container {
    padding: 10px;
  }

  .form-header {
    font-size: 24px;
    margin-right: 10px;
  }

  .form-sub {
    font-size: 14px;
    display: inline;
    margin-left: 0;
  }

  .onboard-form {
    margin-top: 20px;
  }

  .valid,
  .invalid {
    font-size: 12px;
  }

  .login-onboard-form {
    margin-top: 5px;
  }

  .base-question p {
    font-size: 12px;
  }

  .terms-text {
    font-size: 10px;
    font-weight: 400;
  }

  .rline,
  .lline {
    width: 35%;
  }

  .rline {
    margin-right: 5px;
  }

  .lline {
    margin-left: 5px;
  }

  .text {
    font-size: 12px;
  }

  .empty-text {
    font-size: 30px;
  }

  .empty-button {
    width: 80%;
    height: 40px;
    font-size: 14px;
  }

  .job-container {
    margin: auto;
    padding: 0 5%;
  }

  .install-text {
    font-size: 12px !important;
    width: 40%;
    margin-bottom: 0;
  }
  .install-input {
    width: 70% !important;
  }

  .job-text {
    font-size: 0.7rem !important;
    margin-bottom: 10px !important;
    width: 55%;
  }
  .job-dropdown {
    width: 95% !important;
  }

  .uploadLablel {
    width: 95% !important;
  }

  .job-complete-container {
    margin-top: 2.5rem !important;
  }

  .upload-submit {
    width: 60% !important;
  }

  .job-appliance-container {
    padding: 1rem;
  }

  .qty-text {
    font-size: 12px;
  }

  .type-text {
    font-size: 12px;
  }

  .right-header {
    font-size: 12px;
  }

  .appliances {
    width: 100%;
  }

  .appliance-quantity {
    width: 100%;
  }
}

@media screen and (max-width: 330px) {
  .quotes {
    height: 150px;
    padding: 2% 2%;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 5px !important;
  }

  .quote {
    width: 100%;
    height: 180px;
  }

  .quote-info {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .appliances {
    width: 100%;
  }

  .appliance-quantity {
    width: 100%;
  }

  .appliance-container {
    display: block;
  }

  .appliance-btn {
    float: none;
    width: 70% !important;
    margin-top: 1.5rem;
  }

  .app-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .equipment {
    width: 45%;
    height: 145px;
    margin-bottom: 7px;
  }

  .job-appliance-container {
    padding: 0rem;
  }

  .headers {
    padding: 10px;
  }

  .headers p {
    font-size: 12px;
  }

  .amount {
    margin-right: 2rem;
  }
  .item-headers {
    padding: 10px;
  }

  .increment {
    width: 7.67px;
    height: 7.67px;
  }

  .qty-add-bg {
    padding: 3px;
  }

  .qty {
    padding: 0.4px 10px;
  }

  .decrement {
    width: 7.33px;
  }

  .qty-minus-bg {
    padding: 6px 4px;
  }

  .type-text {
    font-size: 12px;
  }

  .watt-qty {
    padding: 0.1px 20px;
  }
}
