.calc-container {
  color: var(--secondary-black);
}

.job-body {
  width: 100%;
  padding: 2rem !important;
}

.btn.cancel-button {
  /* display: flex; */
}

.btn.back-button {
  border: 0 !important;
}

.top-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.cancel-div {
  text-align: center;
}

.btn.cancel img {
  margin-bottom: 3px;
  margin-right: 5px;
}

@media screen and (max-width: 900px) {
  .job-body {
    padding: 10px !important;
  }

  .delete {
    width: 40%;
  }

  .back-button {
    font-size: 14px !important;
  }

  .top-btn {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .btn.cancel-button img {
    height: 20px;
  }

  .indicator-container {
    width: 90%;
  }

  .btn.cancel-button {
    font-size: 12px;
  }

  .cancel-div {
    text-align: left;
  }

  .back-image {
    width: 30%;
  }

  .top-btn {
    margin-left: 0rem;
  }
}
