.admins-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 0 6rem;
  width: 100%;
}

.logo-sidebar img {
  width: 75px;
  height: 65px;
}

.menu {
  cursor: pointer;
}

@media screen and (max-width: 590px) {
  .nav-container {
    padding: 0 1rem;
  }
}
