.quote-backdrop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  /* height: 100%; */
  min-height: 180vh;
  z-index: 10000;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin: auto;
  padding: 60px 20px;
}

.quote-container {
  background: #ffffff;
  padding: 20px;
  width: 80%;
  z-index: 1000;
  height: 100%;
  min-height: 180vh;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.cancel-backdrop {
  position: absolute;
  padding: 10px 10px;
  border-radius: 50%;
  right: 7%;
  cursor: pointer;
}

.cancel-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop-details {
  width: 100%;
  padding: 20px 13% !important;
}

.install-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.install-options p {
  font-size: 0.7rem;
}

.underline {
  border-bottom: 1px solid #fdb303;
  width: 30%;
  margin: auto;
  margin-top: 1.3rem;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container p {
  margin-bottom: 5px;
  font-size: 12px;
}

.second {
  margin-left: -7rem;
}

.third {
  margin-right: 1.5rem;
  /* text-align: left; */
}

.seconds {
  margin-left: -5rem;
}

.thirds {
  margin-right: 1rem;
  /* text-align: left; */
}

.first p {
  font-weight: 600;
}

.vat-text {
  font-size: 13px;
}

.quote-submit {
  height: 40px !important;
}

@media screen and (max-width: 991px) {
  .quote-container {
    padding: 10px;
    width: 80%;
  }

  .backdrop-details {
    width: 100%;
    padding: 20px 1% !important;
  }

  .first {
    width: 30%;
  }

  .second {
    margin-left: -1rem;
  }

  .seconds {
    margin-left: -1rem;
  }
}

@media screen and (max-width: 590px) {
  .quote-container {
    width: 100%;
  }

  .quote-top-links {
    margin-top: 0 !important;
    margin-bottom: 1.5rem !important;
  }

  .quote-links {
    margin-bottom: 1.5rem !important;
  }

  .quote-top-links span {
    font-size: 14px;
  }

  .quote-links span {
    font-size: 14px;
  }

  .logout-quote span {
    font-size: 14px;
  }
}
