.equipment {
  border: 1px solid #bfbfbf;
  padding: 10px;
  width: 30%;
  height: 140px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.width {
  width: 130px !important;
  margin: 5px;
}

.equipment.active {
  border: 1px solid var(--primary-yellow) !important;
}

.equipment img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.name {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}
