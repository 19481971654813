.sidebar-backdrop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    height: 100%;
    min-height: 100vh;
    z-index: 10000;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.sidebar-container {
    background: #ffffff;
    padding: 20px;
    width: 30%;
    position: absolute;
    z-index: 1000;
    right: 0;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.sidebar-top-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 2rem !important;
    margin-bottom: 1.5rem !important;
}

.sidebar-top-links span {
    color: #1b1b1b;
    font-size: 16px;
}

.sidebar-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 1.5rem;
}

.sidebar-links span {
    color: #1b1b1b;
    font-size: 16px;
}

.logout-sidebar {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 5rem !important;
}

.logout-sidebar span {
    color: #ff0000;
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .sidebar-container {
        width: 40%;
    }
    .sidebar-top-links {
        margin-top: 0 !important;
        margin-bottom: 2rem !important;
    }

    .sidebar-links {
        margin-bottom: 2rem !important;
    }

    .sidebar-top-links span {
        font-size: 14px;
    }

    .sidebar-links span {
        font-size: 14px;
    }

    .logout-sidebar span {
        font-size: 14px;
    }
}

@media screen and (max-width: 590px) {
    .sidebar-container {
        width: 80%;
    }

    .sidebar-top-links {
        margin-top: 0 !important;
        margin-bottom: 1.5rem !important;
    }

    .sidebar-links {
        margin-bottom: 1.5rem !important;
    }

    .sidebar-top-links span {
        font-size: 14px;
    }

    .sidebar-links span {
        font-size: 14px;
    }

    .logout-sidebar span {
        font-size: 14px;
    }
}
