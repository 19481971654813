:root {
    --secondary-black: #1B1B1B;
    --slight-black: #5C5C5C;
    --accent-grey: #BFBFBF;
    --primary-yellow: #FDB303;
    --yellow-background: #FFFDFA;
    --grey: #f2f2f2
  }

  body{
    /* font-family: 'Poppins', sans-serif !important; */
  }