.home-banner {
    width: 100%;
    padding: 50px;
    background-color: rgb(255, 244, 224);
    margin: 0px;
    color: var(--secondary-black);
    font-family: "Lato", sans-serif !important;
}

.titleText {
    font-family: "Lato", sans-serif;
    font-weight: 700;
}

.home-button {
    width: 40%;
    height: 41px;
    border-radius: 5px;
    background-color: var(--primary-yellow) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

.button-text {
    font-size: 19px;
    font-style: normal;
}

#paddingZero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
}

.home-button:hover {
    background-color: #1b1b1b !important;
    color: var(--primary-yellow) !important;
    border: 0;
}

#homeButton {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#calcButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 228px;
    height: 41px;
    border-radius: 5px;
    background-color: var(--primary-yellow) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

#calcButton:hover {
    background-color: #1b1b1b !important;
    color: var(--primary-yellow) !important;
    border: 0;
}

.fontStyle {
    font-style: normal;
    font-family: "Lato", sans-serif !important;
    font-weight: 700;
}

.fontStyles {
    font-style: normal;
    font-family: "Lato", sans-serif !important;
    font-weight: 900;
    font-size: 0.9rem;
}

.textStyle {
    font-family: "Lato", sans-serif !important;
}

#media-btn {
    margin: 15px;
    width: 228px;
    font-weight: 500;
    padding: 0;
}

.underline {
    text-decoration: underline;
}

.dark-circle {
    height: 379px;
    width: 379px;
    text-align: center;
    padding-top: 7%;
    border-radius: 50%;
    background-color: var(--secondary-black);
}
.dark-circle > img {
    width: 70%;
}
div.lefts {
    padding-left: 50px;
    padding-top: 3rem !important;
}

div.right {
    display: flex;
    justify-content: center;
}

div.right > span {
    display: block;
}

.tagline {
    float: right;
    font-family: "Lato", sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.bg-color {
    background-color: rgb(255, 244, 224);
}

.home-section {
    padding: 50px !important;
    position: relative;
    text-align: center;
    color: var(--secondary-black);
    font-family: "Lato", sans-serif !important;
}

.black-box {
    position: absolute;
    background-color: var(--secondary-black);
    width: 18px;
    height: 150px;
    top: 0%;
    left: 0%;
}

.section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.media-btn {
    margin: 25px;
}

div.outer {
    margin: 10px;
    width: 200px;
    height: 200px;
    border: 1px solid blue;
    border-radius: 10px;
}

div.inner {
    width: 160px;
    height: 160px;
    border-top-width: 5px solid blue;
    border-bottom: 1px solid blue;
    margin-top: -1px;
    margin-left: -1px;
    background: #fff;
}

.header {
    position: relative;
}

span.header h3 {
    display: inline-block;
    font-weight: 700;
}

span.header .right {
    position: absolute;
    transform: rotate(90deg);
    /* margin-bottom: 30px; */
    top: -7px;
    left: -15px;
}

span.header .left {
    position: absolute;
    transform: rotate(270deg);
    /* margin-top: 10px; */
    bottom: 0px;
    right: -15px;
}

.inner-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 150px;
}

.card-ctn {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.steps-card {
    height: 120px;
    max-width: 259px !important;
    position: relative;
}

.medal_img {
    position: absolute;
    width: 40px;
    right: 10%;
}

.adv-container {
    width: 80%;
    margin-top: 54px !important;
}

.border-right {
    border-right: solid 1px var(--primary-yellow);
}

.img-icons {
    height: 40px;
    width: 40px;
}

.adv-item {
    text-align: left;
    margin-bottom: 45px;
}

.adv-item .card {
    width: 59px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 14px;
}

.adv-item label {
    font-size: 16px;
    font-family: Roboto;
    max-width: 80%;
}

.adv-item h4 {
    display: inline-block;
}

.contact-box {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 120px;
}

.card.icon {
    width: 33px;
    height: 43px;
    display: inline-block;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
    margin-right: 15px;
    padding-top: 0.5%;
}

.contact-box a {
    color: #1b1b1b;
    text-decoration: none;
    margin-right: 40px;
    display: inline-block;
    line-height: 2;
}

.sc-link {
    margin-right: 26px !important;
}

.footer {
    background-color: rgb(255, 244, 224);
    border-top: solid 1px var(--secondary-black);
    text-align: center;
    height: 40px;
    line-height: 2;
}
.logo-text {
    color: var(--primary-yellow);
}

.logo-link img {
    width: 95px;
    height: 75px;
}

/* .logo-link{

} */

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
}

.nav-section {
    width: 55%;
}

.nav-item {
    text-decoration: none;
    color: var(--secondary-black);
    font-size: 18px;
    line-height: 1.1;
    cursor: pointer;
}

.nav-item.active label {
    display: inline-block;
    line-height: 1;
    /* margin-top: 20px; */
    cursor: inherit;
}

.nav-item.active span {
    /* display: inline-block; */
    width: 100%;
    height: 5px;
}

.pos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 5px;
}

.nav-item span.left {
    background-color: var(--secondary-black);
    width: 50%;
}

.nav-item span.right {
    background-color: var(--primary-yellow);
    width: 50%;
}

.about-text {
    font-size: 24px;
    font-family: Roboto;
    font-weight: 300;
}

.banner-text {
    margin-top: 4%;
}

.roboto {
    font-family: "Roboto", "sans-serif";
}

.banner-tag {
    font-weight: 300;
    margin-bottom: 31px;
}

.light-font {
    font-weight: 100;
}

.media-player {
    box-shadow: -16px 14px 34px 2px rgba(0, 0, 0, 0.53);
    -webkit-box-shadow: -16px 14px 34px 2px rgba(0, 0, 0, 0.53);
    -moz-box-shadow: -16px 14px 34px 2px rgba(0, 0, 0, 0.53);
    height: 417px;
    width: 40%;
    margin: 50px;
}

@media screen and (min-width: 901px) and (max-width: 1350px) {
    .nav-section {
        width: 75%;
    }

    .nav-nav {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .desktop {
        display: none !important;
    }

    .nav-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 1%;
    }

    .banner-text {
        margin: 10% 8% 0;
    }
    .home-button.mobile {
        max-width: unset;
    }

    #homeButton {
        width: 49%;
    }

    .fontStyles {
        font-size: 1rem;
    }

    .header {
        margin-top: 4%;
    }

    .headers {
        margin-top: 5%;
    }

    .signin-text {
        font-size: 0.4rem;
    }

    #calcButton {
        width: 50%;
        margin: auto;
    }
    .logo-link img {
        width: 80px;
        height: 60px;
    }

    div.lefts {
        padding: 0px;
        width: 100%;
        text-align: center;
        padding-top: 1.5rem !important;
        padding-bottom: 1rem;
    }

    .home-banner {
        padding: 0 10px;
    }

    .tagline {
        float: inherit;
    }

    .black-box {
        height: 100px;
    }

    .home-section {
        padding: 20px 10px !important;
    }

    .media-player {
        width: 100%;
        height: 300px;
    }

    .media-btn {
        margin: 10px;
    }

    .inner-banner {
        padding: 5px;
    }

    .border-right {
        border: none;
        margin-bottom: 30px;
    }

    .adv-container {
        width: 90%;
    }

    .adv-item label {
        max-width: 85%;
    }

    .contact-box a {
        margin-top: 5px;
    }

    .about-text {
        font-size: 18px;
    }

    .sc-link {
        margin-right: 10px !important;
    }
}

@media screen and (max-width: 590px) {
    .black-box {
        height: 80px;
    }

    .banner-text {
        margin: 10% 8% 2%;
    }

    .button-text {
        font-size: 15px;
        font-style: normal;
    }

    #homeButton {
        width: 75%;
    }

    .fontStyles {
        font-size: 0.8rem;
    }
    #calcButton {
        width: 60%;
        margin: auto;
    }
    .tagline {
        font-size: 25px;
    }
    /* 
    #paddingZero {
        width: 50%;
    } */

    .adv-item label {
        max-width: 70%;
        text-justify: inter-word;
    }

    .about-text {
        font-size: 16px;
    }

    .not-mobile {
        display: none;
    }

    .adv-item h5 {
        font-size: 18px;
    }

    .footer {
        font-size: 12px;
    }

    .sc-link {
        margin-right: 10px !important;
    }
}
