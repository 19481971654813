.received-backdrop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 4.5em;
  position: absolute;
  height: 100%;
  min-height: 190vh;
  top: 0;
  right: 0;
  z-index: 10000;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin: auto;
}

.received-container {
  background: #ffffff;
  padding: 20px;
  width: 40%;
  position: absolute;
  z-index: 1000;
  top: 15%;
  text-align: center;
}

.del-received-container {
  width: 30%;
}

.cancel-btn {
  position: absolute;
  left: 0;
  margin-left: 10px;
  cursor: pointer;
}

.umbrella-container {
  background-color: #fdb303;
}

.bg-umbrella {
  background: #fdb303;
  opacity: 0.5;
  width: 100%;
}

.u-img {
  width: 50%;
}

.del-img {
  width: 35%;
}

.modal-btn {
  height: 40px;
  width: 90%;
  border-radius: 4px !important;
  font-size: 20px !important;
  color: white !important;
  background-color: var(--secondary-black) !important;
  margin-bottom: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  word-spacing: 20%;
}

.verify-btn {
  height: 40px;
  width: 100%;
  border-radius: 4px !important;
  font-size: 16px;
  color: #1b1b1b !important;
  border: 1px solid #1b1b1b !important;
}

.small-text {
  font-size: 12px;
}

.user-text {
  font-size: 16px !important;
}

@media screen and (max-width: 991px) {
  .onboard-button {
    height: 40px;
  }

  .received-container {
    width: 70%;
    top: 10%;
    left: 15%;
  }

  .received-backdrop {
    width: 100%;
  }
}

@media screen and (max-width: 590px) {
  .received-container {
    width: 70%;
    top: 15%;
    left: 15%;
    min-height: 30vh !important;
  }

  .received-backdrop {
    width: 100%;
  }

  .u-img {
    width: 40%;
  }

  div h3 {
    font-size: 14px;
  }

  .small-text {
    font-size: 10px;
  }

  .user-text {
    font-size: 14px;
  }

  .modal-btn {
    height: 35px;
  }

  .verify-btn {
    height: 35px;
  }
}

@media screen and (max-width: 350px) {
  .received-container {
    width: 70%;
    top: 15%;
    left: 15%;
  }

  .user-text {
    font-size: 11px;
  }
}
