.agreement-backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  min-height: 180vh;
  z-index: 10000;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin: auto;
  padding: 10% 20px 30px 20px;
}

.agreement-container {
  background: #ffffff;
  padding: 30px;
  width: 80%;
  z-index: 1000;
  height: 100%;
  min-height: 120vh;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.cancel-backdrop {
  position: absolute;
  padding: 10px 10px;
  border-radius: 50%;
  right: 7%;
}

.cancel-img {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.agreement {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.download-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
}

.download-container p {
  margin-top: 10px;
}

.download-btn {
  width: 30% !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  background-color: #fdb303 !important;
}

.btn-contain {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-groups {
  display: flex;
}

.accept-btn {
  width: 25% !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
}

.decline-btn {
  width: 25% !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  background-color: #fdb303 !important;
}

@media screen and (max-width: 991px) {
  .agreement-backdrop {
    padding: 20% 20px 30px 20px;
    min-height: 240vh;
  }

  .agreement-container {
    padding: 10px;
    width: 100%;
    min-height: 150vh;
  }

  .download-btn {
    width: 70% !important;
  }

  .download-container p {
    font-size: 12px;
    width: 50%;
  }

  .accept-btn {
    width: 40% !important;
    height: 35px !important;
  }

  .decline-btn {
    width: 40% !important;
    height: 35px !important;
  }
}
