.calc-header {
  width: 100%;
  height: 88px;
  background-color: #fafafa;
  border: none;
  padding: 21px;
  padding-left: 100px;
  margin-bottom: 60px;
}

.circle-dark {
  width: 40px;
  height: 40px;
  border: 1px solid var(--secondary-black);
  border-radius: 20px;
  font-size: 16px;
  line-height: 2.4;
}

.calc-container {
  color: var(--secondary-black);
}

.center-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calc-image {
  border-radius: 50%;
}

.innersector-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.question {
  color: var(--secondary-black);
  font-size: 64px;
  font-family: 'Poppins', sans-serif !important;
  text-align: center;
}

.question.font-48{
  font-size: 48px;
}

.salute {
  font-size: 32px;
  font-family: 'Poppins', sans-serif !important;
}

.sub-question {
  font-size: 16px;
  color: var(--slight-black);
}

.yellow-text {
  color: #fdb306;
}

.answer-box {
  width: 400px;
  margin-top: 60px;
  position: relative;
}

.calculator-button {
  height: 48px;
  width: 200px;
  border-radius: 4px !important;
  font-size: 16px;
  color: white !important;
  background-color: var(--secondary-black) !important;
  margin-top: 80px;
}

.calculator-button:disabled {
  background-color: var(--accent-grey) !important;
  color: white !important;
}

.suggest-box{
  max-width: 600px;
  margin-top: 80px;
  text-align: center;
}

.main-body{
  width: 100%;
  margin: 0px !important;
}

.cancel-div{
  text-align: center;
}

.btn.cancel img{
  margin-bottom: 3px;
  margin-right: 5px;
}


@media screen and (max-width: 900px) {
  .calc-header{
    padding-left: 0px;
    margin-bottom: 30px;
    height: 60px;
    padding: 10px;
  }

  .calc-header img{
    height: 40px;
  }

  .question {
    font-size: 40px !important;
    text-align: center;
  }

  .sub-question{
    text-align: center;
  }

  .salute {
    font-size: 24px;
  }

  .calculator-button {
    margin-top: 120px;
  }
 .center-section{
   padding-top: 90px;
   min-height: 800px;
 }

 .circle-dark {
  width: 30px;
    height: 30px;
    border: 1px solid var(--secondary-black);
    border-radius: 15px;
    font-size: 12px;
    line-height: 2.4;
}

}

@media screen and (max-width: 500px) {
  .question {
    font-size: 24px !important;
    text-align: center;
  }

  .btn.cancel img{
    height: 20px;
  }

  .btn.cancel {
    font-size: 12px;
  }

  .salute {
    font-size: 18px;
  }
  .sub-question{
    font-size: 12px;
    text-align: center;
  }

  .answer-box{
    width: 90%;
    margin-top: 40px;
  }
  
  .calc-image {
    height: 80px;
  }
  .calculator-button {
    font-size: 12px !important;
    height: 40px;
    margin-top: 20px;
  }
  .suggest-box{
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: block;
  }

  .center-section{
    padding-top: 30px;
    min-height: 600px;
  }

  .cancel-div{
    text-align: left;
  }
}

