.onboarding-container {
  background-color: var(--yellow-background);
  padding: 5px 60px !important;
}

.onboard-image {
  width: 55%;
}

.text-img-container {
  margin-top: 30px;
  text-align: center;
}

.text-img-container h2 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.onboard-button {
  height: 48px;
  width: 100%;
  border-radius: 4px !important;
  font-size: 16px;
  color: white !important;
  background-color: var(--secondary-black) !important;
}

.onboard-button:disabled {
  background-color: var(--accent-grey) !important;
  color: white !important;
}

@media screen and (max-width: 991px) {
  .onboarding-container span {
    display: none;
  }
  .onboard-image {
    width: 60%;
  }
  .text-img-container h2 {
    display: none;
  }

  .onboard-image {
    width: 20%;
  }

  .text-img-container {
    margin-top: 15px;
  }

  .onboard-button {
    height: 40px;
  }
}

@media screen and (min-width: 430px) and (max-width: 1000px) {
  .onboard-image {
    width: 50%;
  }
}

@media screen and (max-width: 590px) {
  /* .onboarding-container {
        height: 0;
        max-height: 0;
    } */
}
