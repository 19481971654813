.unit-class {
    color: var(--secondary-black);
    background-color: var(--primary-yellow);
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    width: 56px;
    text-align: center;
    line-height: 3;
    border-radius: 3px;
    font-size: 16px;
}

.suggest-button {
    height: 32px;
    width: 98px;
    border-radius: 30px !important;
    background: #ffffff;
    font-size: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin: 15px;
    margin-bottom: 22px;
}

.calc-input {
    font-size: 16px !important;
}

.calc-input:focus {
    border: 1px solid #1b1b1b !important;
    box-shadow: none !important;
}

@media screen and (max-width: 500px) {
    .calc-input {
        font-size: 12px !important;
    }
    .unit-class {
        font-size: 12px;
    }

    .suggest-button {
        width: 79px;
        height: 32px;
        font-size: 12px !important;
    }

    .suggest-button-box {
        width: 140%;
        max-width: 140%;
    }
}
