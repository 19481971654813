.job {
  width: 482px !important;
  margin-right: 0.7rem !important;
  position: relative;
  height: 225px !important;
}

.indicator {
  border: 1px solid #1b1b1b;
  width: 10%;
  padding: 2px;
  border-radius: 4px;
  margin-right: 5px;
}

.quote-active {
  background-color: #fdb303;
}

.house-img {
  width: 8%;
  margin-right: 7px;
}

.info-container {
  display: flex;
  align-items: center;
}

.apartment-type {
  display: flex;
  align-items: center;
  margin-right: 1rem !important;
}

.info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-quote {
  width: 45% !important;
  margin: 0 !important;
}

.moreinfo-container {
  background-color: #fff;
  position: absolute;
  top: -0.7%;
  left: 0;
  z-index: 999999999;
  padding: 1% 5%;
  border: 1px solid #bfbfbf;
  width: 100%;
  border-radius: 4px;
}

.moreinfo {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.moreinfo1 {
  margin-right: 3rem;
}

.moreinfo-header {
  font-weight: 600;
  font-size: 16px;
}

.moreinfo p {
  margin: 0;
}

.moreinfo1 p,
.moreinfo2 p {
  font-size: 14px;
}

.moreinfo1-header,
.moreinfo2-header {
  font-weight: 600;
  font-size: 16px;
}

.bidded-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bidded-img {
  float: right;
}

.bidding-type {
  margin-right: 0 !important;
}

.bidded-jobs {
  display: flex;
  align-items: center;
}

.bidded {
  height: 220px !important;
}

@media screen and (max-width: 901px) {
  .bidded {
    height: 210px !important;
  }

  .job-quote {
    width: 49% !important;
  }

  .job-quote span {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 590px) {
  .bidded {
    height: 155px !important;
  }

  .job {
    height: 165px !important;
  }

  .house-img {
    margin-right: 5px;
  }

  .heart-img {
    width: 14% !important;
  }

  .calender-icon {
    width: 8% !important;
  }

  .apartment-type {
    font-size: 12px;
    margin-right: 0px !important;
  }

  .job-quote {
    width: 55% !important;
    /* height: 30px !important; */
    margin: 2px !important;
  }

  .job-quote span {
    font-size: 13px !important;
  }

  .moreinfo1 p,
  .moreinfo2 p {
    font-size: 12px;
  }

  .moreinfo-header {
    font-weight: 600;
    font-size: 16px;
  }

  .moreinfo h3 {
    font-size: 16px !important;
  }

  .moreinfo p {
    margin: 0;
  }

  .moreinfo1-header,
  .moreinfo2-header {
    font-weight: 600;
    font-size: 16px;
  }

  .bidded-jobs {
    display: block;
  }

  .bidded-container {
    margin-bottom: 0px;
  }
}
