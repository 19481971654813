.quote-received-backdrop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 4.5em;
  position: absolute;
  height: 100%;
  min-height: 260vh !important;
  top: 0;
  right: 0;
  z-index: 10000;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin: auto;
}

.quote-received-container {
  background: #ffffff;
  padding: 20px;
  width: 25%;
  height: 400px;
  position: absolute;
  /* min-height: 85vh !important; */
  z-index: 1000;
  top: 10%;
  text-align: center;
}

h3,
form {
  text-align: left;
}

.u-img {
  width: 70%;
}

.quote-modal-btn {
  height: 40px;
  width: 100%;
  border-radius: 4px !important;
  font-size: 20px !important;
  color: white !important;
  background-color: var(--secondary-black) !important;
  margin-bottom: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  word-spacing: 20%;
}

.quote-cancel-backdrop {
  position: absolute;
  padding: 5px;
  border-radius: 50%;
  right: 7%;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .quote-received-container {
    width: 70%;
    min-height: 85vh !important;
    top: 10%;
    left: 15%;
  }

  .quote-received-backdrop {
    width: 100%;
    min-height: 272vh !important;
  }
}

@media screen and (max-width: 590px) {
  .quote-received-container {
    width: 70%;
    height: 360px;
    left: 15%;
    min-height: 30vh !important;
  }

  .quote-received-backdrop {
    width: 100%;
    min-height: 200vh !important;
  }

  .u-img {
    width: 100%;
  }

  label {
    font-size: 12px;
    margin-left: 5px;
  }

  .quote-modal-btn {
    height: 35px;
  }

  .quote-cancel-backdrop {
    padding: 1px;
  }

  form div {
    display: inline-flex;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .quote-received-backdrop {
    width: 100%;
    min-height: 260vh !important;
  }
}

@media screen and (max-width: 350px) {
  .quote-received-backdrop {
    width: 100%;
    min-height: 336vh !important;
  }
  .quote-received-container {
    width: 70%;
    top: 15%;
    left: 15%;
  }
}
