.appliance-item{
    width: 150px;
    min-height: 160px;
    margin: 15px !important;
    display: inline-block !important;
    cursor: pointer;
}

.appliance-item img{
   height: 60px;
}

.appliance-button{
    margin-top: 80px !important;
}

.appliance-item.active{
    border: 1px solid var(--primary-yellow) !important;
}

.pagination-container{
    width: 100%;
}

.auto-width{
    width: auto !important;
}

.dot{
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #C4C4C4;
  display: inline-block;
  margin: 6px;
}

.dot.active{
    background-color: var(--primary-yellow) !important;
}

.nextSlide-button{
  cursor: pointer;
  margin-bottom: 13px;
}

.calculator-container{
    width: 100%;
}

.result-header{
    font-size: 24px;
    font-family: 'Poppins', sans-serif !important;
}

.result-box{
    width: 100%;
    background-color: var(--grey);
    border-radius: 4px;
    min-height: 50px;
    font-size: 16px;
}

.result-boxinner{
    padding: 20px;
    padding-left: 0px;
    margin-left: 15px;
}

.result-box img{
    margin-right: 16px;
}

.red-strip{
    height: 130px;
    width: 8px;
    border-radius: 4px 0px 0px 4px;
    background-color: #FF0000;
    float: left;
}

.red-strip.green{
    background-color: #65DD6A;
}

.estimate-box{
 margin-top :  80px;
 font-family: 'Poppins', sans-serif !important;
}

@media screen and (max-width: 500px){
    .card.appliance-item .card-body{
        padding: 7px;
    }
    .appliance-item{
        width: 104px;
        max-height: 110px;
        min-height: 110px;    
    }

    .appliance-item img{
        height: 40px;
    }

    .appliance-item label{
        font-size: 12px;
    }

    .appliance-button{
        margin-top: 20px !important;
    }

    .result-header{
        font-size: 16px;
    }

    .result-box{
        font-size: 12px;
        height: 100px;
    }

    .result-boxinner{
        padding: 6px;
    }

    .estimate-box{
        margin-top :  40px
       }
    
       .red-strip{
           height: 100%;
       }
    
}